import React from 'react'
import sadImg from '../assets/logonovo.png'

const Manutencao = () => {
  return (
    <div className=' flex flex-col items-center justify-center mx-auto '>
    <img className='' src={sadImg} alt="Fábio na suiça a passar fome graças ao nelson" />
    <h1 className='text-5xl text-white'>ROADPARK.PT</h1>
    <p className='text-2xl text-white italic mt-2'>Domínio à venda.</p>
    </div>
  )
}

export default Manutencao